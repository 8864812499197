import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Divider from '../common/divider';

export default () => {
    const data = useStaticQuery(query);

    const imageSrc = data.image.childImageSharp.fluid.src;

    return (
        <>
            <section id="dostavki-address">

                <Container className="py-5">

                    <Row className="my-5 d-flex">
                        <Col lg={6} className="text-center text-lg-left order-1">
                            <h3>Нашето меню</h3>
                            <Divider />

                            <Image src={imageSrc} fluid alt="Нашето меню" className="d-lg-none my-4" />
                            <p>Всичките ни гозби са с неповторими вкусове, а рецептите намират корени в земите ни още от древността. Изключителното качество на продуктите, които използваме допринася за прекрасния вкус  на всяко едно ястие в нашето меню.
                                <br /><br />Искаме да подарим на гостите си незабравимо изживяване с и да запечатаме добрите спомени и моменти. Майсторите ни готвачи са внимателни към всеки един елемент от приготвянето на храната, от фермите и градините, през дългогодишния им опит като готвачи. Те пресъздават традиционната кухня, която впечатлява с вкусове всеки, който я опита.</p>


                            <Link to={'/menu'} className="btn btn-secondary mt-3">Разгледай тук</Link>
                        </Col>

                        <Col lg={6} className="d-none d-lg-flex justify-content-start order-0">
                            <Image src={imageSrc} fluid alt="Нашето меню" className="index-image" />
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    )
}

const query = graphql`
    query IndexMenu {
        image: file(relativePath: {eq: "index/menu.jpg"}) {
            childImageSharp {
                fluid(quality: 60, maxHeight: 450) {
                    src
                }
            }
        }
    }
`;
