import { graphql } from "gatsby"
import React from "react"
import Map from "../components/common/map"
import DividerParalax from "../components/index/divider-paralax"
import Hero from "../components/index/hero"
import LeftText from "../components/index/left-text"
import Reservation from "../components/index/reservation"
import RightText from "../components/index/right-text"
import USP from "../components/index/usp"
import Layout from "../components/site-wide/layout/layout"
import constants from "../utils/constants"

const IndexPage = ({ data }) => (
  <Layout
    title={constants.INDEX.META.TITLE}
  >
    <Hero />
    <Reservation />
    <LeftText />
    <DividerParalax bg={data.paralax.childImageSharp.fluid.src} />


    <RightText />

    <USP />

    <Map />

  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    paralax: file(relativePath: {eq: "index/paralax-1.jpg"}) {
        childImageSharp {
            fluid(quality: 100) {
                src
            }
        }
    }
  }
`;