import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import constants from '../../utils/constants';
import Divider from '../common/divider';

export default () => {
    const data = useStaticQuery(query);

    const imageSrc = data.image.childImageSharp.fluid.src;

    return (
        <>
            <section id="dostavki-address">

                <Container className="py-5">

                    <Row className="my-5">
                        <Col lg={6} className="text-center text-lg-left">
                            <h3>Доставки до адрес</h3>
                            <Divider />

                            <Image src={imageSrc} fluid alt="Доставки до адрес" className="d-lg-none my-4" />
                            <p>Добрата храна е това, което доставяме! Разнообразното ни меню те очаква с вкусове от всички точки на България.
                            <br /><br />Доставяме до следните райони: Белите брези, Борово, Гоце Делчев, Иван Вазов  Манастирски ливади, Мотописта, Стрелбище, Красно село.
                            <br /><br />Обади се и поръчай, идваме до няколко минути.</p>


                            <a href={`tel:${constants.TELEPHONE}`} className="btn btn-primary mt-3">Поръчай на {constants.TELEPHONE}</a>
                        </Col>

                        <Col lg={6} className="d-none d-lg-flex justify-content-end">
                            <Image src={imageSrc} fluid alt="Доставки до адрес" className="index-image" />
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    )
}

const query = graphql`
    query IndexDelivery {
        image: file(relativePath: {eq: "index/delivery.jpg"}) {
            childImageSharp {
                fluid(quality: 100, maxHeight: 450) {
                    src
                }
            }
        }
    }
`;
