import { useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
    min-height: 400px;
    width: 100%;

    background: url(${({ bgImage }) => bgImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;


    @media screen and (max-width: 991px) {
        background-attachment: unset !important;
    }
`;

export default ({ children, bg }) => {
    const data = useStaticQuery(query);
    const bgImage = data.image.childImageSharp.fluid.src;

    return (
        <>
            <section id="divider-paralax">
                <Wrapper bgImage={bg || bgImage}>
                    {children}
                </Wrapper>
            </section>
        </>
    )
}

const query = graphql`
    query IndexParalax {
        image: file(relativePath: {eq: "index/paralax.jpg"}) {
            childImageSharp {
                fluid(quality: 100) {
                    src
                }
            }
        }
    }
`;
