import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import constants from '../../utils/constants';
import Divider from '../common/divider';

const Wrapper = styled.div`
    background: rgba(0, 0, 0, 0.75);
    height: calc(100vh - 100px);

    .bgImage {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: calc(100vh - 100px);
        object-fit: cover;
    }
`;

const Background = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100vh - 100px);
`;

const Content = styled.div`
    position: absolute;
    z-index: 10;
    color: white;
    width: 100%;
    height: 100%;

    margin-top: -100px;
    padding-top: 80px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    .name {
        font-size: 72px;
        @media screen and (max-width: 576px) {
            font-size: 40px;
        }
    }


    h1 {
        font-size: 92px;
        @media screen and (max-width: 576px) {
            font-size: 60px;
            line-height: 1;
        }
    }

    @media screen and (max-width: 576px) {
        padding-top: 120px;
        padding-left: 15px;
        padding-right: 15px;
    }
`;

const Hero = () => {

    const data = useStaticQuery(query);

    const bgImage = data.bgImage.childImageSharp.fluid.src;

    return (
        <>
            <Wrapper>
                <img src={bgImage} className="bgImage" />
                <Background className="content">
                    <Content>
                        <div className="text-center">
                            <h1><span className="text-white">Вкусът на българското</span><br /><span className="text-gold name">Поръчай храна за вкъщи</span></h1>
                            <Divider addClassName="gold" />

                            <p><a href={`tel:${constants.TELEPHONE}`} className="btn btn-secondary mt-3">{constants.TELEPHONE}</a></p>
                        </div>
                    </Content>
                </Background>
            </Wrapper>
        </>
    )

}

const query = graphql`
    query IndexHero {
        bgImage: file(relativePath: {eq: "index/hero.jpg"}) {
            childImageSharp {
                fluid(quality: 100) {
                    src
                }
            }
        }
    }
`;


export default Hero;