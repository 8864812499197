import React, { useReducer } from 'react';
import emailjs from 'emailjs-com';
import styled from 'styled-components';
import { Container, Form, Button, Col } from 'react-bootstrap';
import { DatePicker, TimePicker, InputNumber, notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/bg';
import locale from 'antd/lib/locale/bg_BG';
import Divider from '../common/divider';
import { Link } from 'gatsby';


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const initState = {
    isLoading: false,
    date: null,
    time: null,
    numberPeople: 2,

    names: null,
    namesIsValid: null,

    phone: null,
    phoneIsValid: null,

    email: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'value.change':
            return {
                ...state,
                [action.name]: action.value
            }

        case 'submit':
            return {
                ...state,
                isLoading: true,
            }

        case 'stop':
            return {
                ...state,
                isLoading: false,
            }
        case 'reset':
            return {
                ...initState
            }
        default:
            return state;
    }
}


const timeFormat = 'HH:mm';

const Wrapper = styled.div`
    z-index: 1;
    background: transparent;    
    width: 100%;
    
    .inner-container {
        padding: 30px;
        background: #fff;
        margin-top: -150px;
        -webkit-box-shadow: -1px 38px 60px 0px rgba(0,0,0,0.25);
        -moz-box-shadow: -1px 38px 60px 0px rgba(0,0,0,0.25);
        box-shadow: -1px 38px 60px 0px rgba(0,0,0,0.25);


        @media screen and (max-width: 400px) {
            margin-top: -70px;
        }
    }
`;

const Reservation = () => {
    const [state, dispatch] = useReducer(reducer, initState);

    const disableHours = () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 23];
    const disableDates = (date) => date.isBefore(moment(), 'day');

    const validateInputs = () => {

        const invalidFields = [];

        if (state.date == null) {
            invalidFields.push('Дата');
        }
        if (state.time == null) {
            invalidFields.push('Час');
        }
        if (state.names == null || state.names.length < 1) {
            invalidFields.push('Имена');
            dispatch({ type: "value.change", name: 'namesIsValid', value: true });
        }
        if (state.phone == null) {
            invalidFields.push('Телефон');
            dispatch({ type: "value.change", name: 'phoneIsValid', value: true });
        }


        if (invalidFields.length > 0) {
            // SEND notification

            notification.open({
                message: "Неуспешно изпращане",
                description: `Следните полета са задължителни: ${invalidFields.join(', ')}`,
                className: 'error-notification',
                duration: 5,

                style: {
                    width: 450,
                }
            })
            dispatch({ type: 'stop' });
            return;
        }
        // SEND EMAIL here

        const templateData = {
            date: moment(state.date).format("DD MMMM YYYY"),
            hour: moment(state.time).format("HH:mm"),
            persons: state.numberPeople,
            telephone: state.phone,
            reply_to: state.email || "Не е специфициран",
            from_name: state.names,
        }

        emailjs.send("gmail", "reservation", templateData, "user_Vp5E0D0wuU5bkmCZk37kP").then((function (e) {

            notification.open({
                message: "Успешно направихте резервация",
                description: `Очаквайте скоро да се свържем с Вас!`,
                className: 'success-notification',
                duration: 5,

                style: {
                    width: 450,
                }
            });

            dispatch({ type: 'reset' });

        }), (function (e) {
            console.log("🚀 ~ file: reservation.js ~ line 135 ~ e", e)
            notification.open({
                message: "Неуспешно изпращане",
                description: `Моля опитайте отново!`,
                className: 'error-notification',
                duration: 5,

                style: {
                    width: 450,
                }
            })
            dispatch({ type: 'stop' });

        }));

    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        dispatch({ type: 'submit' });
        validateInputs();
    }

    return (
        <>
            <Wrapper className="mb-5">
                <Container className="inner-container">
                    <div className="text-center my-4">
                        <h2 className="">Направи онлайн резервация</h2>
                        <Divider addClassName="mt-1 mb-4" />
                        <div className='reservations-form text-left px-md-5'>
                            <Form onSubmit={handleSubmitForm}>
                                <Form.Row>
                                    <Form.Group as={Col} xs={6} lg={2} controlId="formGridEmail">
                                        <Form.Label>Дата:</Form.Label>
                                        <div>
                                            <DatePicker
                                                value={state.date}
                                                locale={locale}
                                                disabledDate={disableDates}
                                                showToday={false}
                                                showNow={false}
                                                format={"DD-MM-YYYY"}
                                                size='large'
                                                placeholder="Избери дата"
                                                onChange={(date) => { dispatch({ type: "value.change", name: 'date', value: date }) }}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6} lg={2} controlId="formGridPassword">
                                        <Form.Label>Час:</Form.Label>
                                        <div>
                                            <TimePicker
                                                value={state.time}
                                                hideDisabledOptions={true}
                                                format={timeFormat}
                                                showNow={false}
                                                minuteStep={30}
                                                size='large'
                                                placeholder="Избери час"
                                                disabledHours={disableHours}
                                                onChange={(time) => { dispatch({ type: "value.change", name: 'time', value: time }) }}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6} lg={2} controlId="formGridAddress2">
                                        <Form.Label>Брой души:</Form.Label>
                                        <div>
                                            <InputNumber
                                                value={state.numberPeople}
                                                min={1}
                                                max={15}
                                                defaultValue={2}
                                                className="input-number"
                                                onChange={(val) => { dispatch({ type: "value.change", name: 'numberPeople', value: val }) }}
                                            />
                                        </div>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={6} controlId="formGridAddress1">
                                        <Form.Label>Имена:</Form.Label>
                                        <Form.Control
                                            value={state.names}
                                            placeholder="Въведи имена"
                                            className="customInput"
                                            isInvalid={state.namesIsValid}
                                            onChange={({ target }) => { dispatch({ type: "value.change", name: 'names', value: target.value }) }}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xs={6} controlId="formGridCity">
                                        <Form.Label>Телефон:</Form.Label>
                                        <Form.Control
                                            value={state.phone}
                                            placeholder="Въведи телефон"
                                            className="customInput"
                                            isInvalid={state.phoneIsValid}
                                            onChange={({ target }) => { dispatch({ type: "value.change", name: 'phone', value: target.value }) }}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xs={6} controlId="formGridCity">
                                        <Form.Label>Имейл:</Form.Label>
                                        <Form.Control
                                            value={state.email}
                                            placeholder="Въведи имейл"
                                            className="customInput"
                                            onChange={({ target }) => { dispatch({ type: "value.change", name: 'email', value: target.value }) }}
                                        />
                                    </Form.Group>

                                </Form.Row>

                                <div className="text-center mt-3">
                                    {!state.isLoading ?
                                        (
                                            <>
                                                <Button variant="primary" type="submit">
                                                    Направи резервация
                                                </Button>
                                            </>
                                        ) : (
                                            <Spin indicator={antIcon} />
                                        )}

                                </div>
                            </Form>

                            <div className="text-right mt-5">
                                <p className="mb-0">За резервация на по-големи събития <Link to='/kontakti' className="text-gold">свържете с нас</Link>.</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </Wrapper>
        </>
    )
}

export default Reservation;